import React from 'react';
import cofreImg from './../images/cofre100.png';
import tiendaImg from './../images/tienda112.png';

const UserMenu = () => {
    return (
        <div className="topCommands commandsBox">
        <div className='navTops heroes unselectable'>
          <div className='btnForallia'><div className='flex'><img src={cofreImg} alt="Cartas"/><span>Cartas</span></div></div>
          <div className='btnForallia'><div className='flex'><img src={tiendaImg} alt="Tienda"/><span>Tienda</span></div></div>
        </div>
      </div>
    )
}

export default UserMenu;